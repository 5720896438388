<template>
  <div class="p-5 history-modal text-center">
    <div v-if="client.isActive">
      This will deactivate all accounts under {{client.name}}. Customers will no longer be able to login and create tickets.
    </div>
    <div v-else>
      This will activate all accounts under {{client.name}}. Customers will be able to login and create tickets again.
    </div>
    <br>
    Are you sure you want to continue?
    <br><br>
    <div class="w-100 mt-3">
      <div class="btn border-0 px-4 py-2 btn-primary" v-on:click="updateClientStatus">Continue</div>&nbsp;
      <div class="btn border-0 px-4 py-2 btn-dark bg-escalated" v-on:click="cancelMethod">Cancel</div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      client: Object
    },
    data() {
      return {
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      updateClientStatus: function(val){
          this.$emit('updateClientStatus', val)
      },
      cancelMethod: function(){
        this.$modal.toggle('toggle-status');
      }
    }
  }
</script>
