<template>
  <div>
    <div class="title-path">
      <a href="/">Home</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a href="/clients">Clients</a>
      <small class="fas fa-angle-right mx-1"></small>
      <a :href="`/clients/${code}`">{{code}}</a>
    </div>
    <div class="row issue-wrapper" v-if="client._id">
      <div class="col col-auto-5 issue-col p-4 bg-white">
        <div class="row px-2" v-if="formControl.profile">
          <div class="col-12 pt-2">
            <h6 class="text-dark text-header">
              Company Name
              <div class="float-right">
                <Toggle
                  v-model="client.isErp"
                  class="toggle"
                >
                  <template v-slot:label="{ checked, classList }">
                    <span :class="classList.label">{{ checked ? 'ERP' : 'ERP' }}</span>
                  </template>
                </Toggle>
              </div>
            </h6>
            <input class="form-control" v-if="!client.isErp" placeholder="Company name" v-model="client.name"/>
            <span ref="erpSelect" v-if="client.isErp">
              <ModelSelect
                v-model="client.erpName"
                placeholder="Search ERP Client"
                :options="options.erpClients"
              >
              </ModelSelect>
            </span>
            <br>
            <h6 class="text-dark text-header">Client Code</h6>
            <input class="form-control" placeholder="Client code" v-model="client.code" disabled/>
            <br>
            <h6 class="text-dark text-header">Client Segment</h6>
            <ModelSelect
              v-model="client.segment"
              placeholder="Client Segment"
              :options="options.segments"
            >
            </ModelSelect>
            <br>
            <div  v-if="this.user.role == 'Admin'">
              <h6 class="text-dark text-header">Deployment Phase</h6>
              <ModelSelect
                v-model="client.phase"
                placeholder="Deployment phase"
                :options="options.phases"
              >
              </ModelSelect>
              <br>
            </div>
            <h6 class="text-dark text-header">Company logo</h6>
            <input class="form-control" placeholder="URL of company logo" v-model="client.logo"/>
            <br>
          </div>
          <div class="col-4">
            <img class="w-100" :src="validateLogo(client.logo)">
          </div>
          <div class="col-8">
            <textarea class="form-control h-100" rows="7" placeholder="Write something about this client" v-model="client.remarks"></textarea>
          </div>
          <div class="col-12">
            <br>
            <div class="w-100 text-right">
              <div :class="'btn btn-sm border-0 text-light px-3 py-2 d-inline ' + (client.isActive ? 'btn-dark' : 'bg-success')" v-on:click="toggleStatusModal" v-if="formControl.status">
                <i :class="'text-white fas fa-' + (client.isActive ? 'lock' : 'unlock')"></i>&nbsp; {{client.isActive ? 'Deactivate' : 'Activate'}} client
              </div>&nbsp;
              <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline" v-on:click="updateClientProfile">
                <i class="text-white fas fa-save"></i>&nbsp; Update client
              </div>
            </div>
          </div>
        </div>
        <div class="row p-5" v-else>
            <div class="col-3">
            </div>
            <div class="col-6">
              <img class="w-100" :src="validateLogo(client.logo)">
            </div>
            <div class="col-3">
            </div>
            <div class="col-12 text-center py-3 client-static">
              <h6 class="text-dark text-header">{{client.code}} | {{client.name}}</h6>
              <label>{{projects.map(r => r.bunit.name).join(', ')}}</label>
              <br><br>
              <p>{{client.remarks || 'No description has been provided.'}}</p>
            </div>
        </div>
      </div>
      <div class="col-lg-7 issue-col issue-properties shadow-0 border-md-left p-0 m-0">
        <div class="row px-2 m-0 h-100 w-100">
          <div class="col-md-6 p-4 issue-col bg-white">
            <div class="mb-3 pb-4 border-bottom" v-if="formControl.project.new">
              <div class="property-dynamic">
                <!-- <div class="float-right btn btn-sm btn-primary cursor-pointer" @click="toggleIntegrations">
                  <i class="fas fa-cog"></i>&nbsp; Configure
                </div>
                <h6 class="pt-2 text-dark text-header">Integrations</h6>
                <hr class="my-4"> -->
                <h6 class="pt-2 text-dark text-header">Gitlab Repository</h6>
                <span ref="searchSelect" v-on:click="() => {this.loaded = true}">
                  <ModelSelect
                    v-model="project"
                    placeholder="Search Gitlab repository"
                    :options="options.gitProjects"
                  >
                  </ModelSelect>
                </span>
                <h6 class="pt-3 text-dark text-header">Business Unit</h6>
                <ModelSelect
                  v-model="bunit"
                  placeholder="Select business unit"
                  :options="options.bunits"
                >
                </ModelSelect>
              </div>
              <div class="w-100 mt-4 text-right" v-on:click="createClientProject">
                <div class="btn btn-sm btn-primary border-0 px-3 py-2 d-inline">
                  <i class="fas fa-plus"></i>&nbsp; Add project
                </div>
              </div>
            </div>
            <h6 class="text-dark text-header">Gitlab Projects</h6>
            <div class="property-static" v-for="project in projects" v-bind:key="project._id">
              <div class="row p-3">
                <div class="col-auto p-0" target="_blank">
                  <img class="agent-avatar" :src="$getAvatar(`/images/avatars/gitAvatar.svg`)">
                </div>
                <div class="col p-0">
                  <label class="text-property text-bolder">
                    <span>{{project.gitLab.title}}</span><br>
                    <span class="text-muted">{{project.bunit.name}}</span><br>
                    <Toggle
                      v-model="project.disabled"
                      class="toggle-inverted mt-2"
                      v-if="formControl.project.update"
                      v-on:change="updateClientProject(project)"
                    >
                      <template v-slot:label="{ checked, classList }">
                        <span :class="classList.label">{{ checked ? 'Inactive' : 'Active' }}</span>
                      </template>
                    </Toggle>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 issue-col bg-white border-md-left p-3">
            <div class="property-dynamic">
              <h6 class="pt-2 text-dark text-header">Ticket Counter</h6>
              <a :href="`/tickets?client=${this.client.code}`">
                <div class="text-center my-4 cursor-pointer" v-tooltip="`View all tickets`">
                  <div class="percent-statistics">
                    <div>{{ticketStatistics.percentCompleted | rounded}}%</div>
                    <label>Completed from <label>{{ticketStatistics.totalCount}}</label> total tickets</label>
                  </div>
                </div>
              </a>
              <div class="row side-bar-stats mb-3 text-center">
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=pending`" v-tooltip="`View all pending tickets`">
                  <h3>{{ticketStatistics.totalPending | zeroPad}}</h3>
                  <small>Pending</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=acknowledged`" v-tooltip="`View all acknowledged tickets`">
                  <h3>{{ticketStatistics.totalAcknowledged | zeroPad}}</h3>
                  <small>Acknowledged</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=inProgress`" v-tooltip="`View all in progress tickets`">
                  <h3>{{ticketStatistics.totalinProgress | zeroPad}}</h3>
                  <small>In Progress</small>
                </a>
              </div>
              <div class="row side-bar-stats mb-3 text-center">
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=escalated`" v-tooltip="`View all escalated tickets`">
                  <h3>{{ticketStatistics.totalEscalated | zeroPad}}</h3>
                  <small>Escalated</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=resolved`" v-tooltip="`View all resolved tickets`">
                  <h3>{{ticketStatistics.totalResolved | zeroPad}}</h3>
                  <small>Resolved</small>
                </a>
                <a class="col col-4" :href="`/tickets?client=${this.client.code}&status=closed`" v-tooltip="`View all closed tickets`">
                  <h3>{{ticketStatistics.totalClosed | zeroPad}}</h3>
                  <small>Closed</small>
                </a>
              </div>
              <hr class="my-4">
              <h6 class="pt-2 text-dark text-header">User Counter</h6>
              <a class="row side-bar-stats mb-3 text-center cursor-pointer" :href="`/customers?client=${this.client.code}`" v-tooltip="`View all customers`">
                <div class="col col-4">
                  <h3>{{customerStatistics.totalUsers | zeroPad}}</h3>
                  <small>Total</small>
                </div>
                <div class="col col-4">
                  <h3>{{customerStatistics.totalActiveUsers | zeroPad}}</h3>
                  <small>Active</small>
                </div>
                <div class="col col-4">
                  <h3>{{customerStatistics.totalInactiveUsers | zeroPad}}</h3>
                  <small>Inactive</small>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal name="toggle-status" :transition="`fade`" :width="`500px`" :height="`auto`" :scrollable="true">
      <ToggleStatusComponent :client="client" @loader="loader($event)" @updateClientStatus="updateClientStatus"/>
    </modal>
    <!-- <modal name="integrations" :transition="`fade`" :width="isMobile ? `300px` : `500px`" :height="`auto`" :scrollable="true">
      <IntegrationsComponent :client="client"/>
    </modal> -->
  </div>
</template>

<script>
  import Toggle from '@vueform/toggle/dist/toggle.vue2.js'
  import ToggleStatusComponent from  './ClientModals/ToggleStatus.vue'
  // import IntegrationsComponent from  '../Plugins/Clients/Integrations.vue'
  import { ModelSelect } from 'vue-search-select'

  export default {
    components: {
      Toggle,
      ModelSelect,
      ToggleStatusComponent
      // IntegrationsComponent
    },
    props: {
      code: String,
      isMobile: Boolean
    },
    data() {
      return {
        user: this.$userData ? this.$userData.user : {},
        client: {},
        clients: [],
        ticketStatistics: {
          percentCompleted: 0,
          totalPending: 0,
          totalAcknowledged: 0,
          totalinProgress: 0,
          totalEscalated: 0,
          totalResolved: 0,
          totalClosed: 0,
          totalOpen: 0,
          totalCount: 0
        },
        customerStatistics: {
          totalUsers: 0,
          totalActiveUsers: 0,
          totalInactiveUsers: 0
        },
        projects: [],
        gitProjects: [],
        search: '',
        erpSearch: '',
        erpToken: '',
        imageURL: '',
        options: {
          gitProjects: [],
          bunits: [],
          erpClients: [],
          segments: this.$segments || [],
          phases: [
            { key: 'ph0', text: 'Phase 0', value: 0 },
            { key: 'ph1', text: 'Phase 1', value: 1 },
          ]
        },
        project: {},
        bunit: {},
        totals: {},
        formControl: {
          profile: false,
          status: false,
          project: {
            new: false,
            update: false
          },
          integrations: {
            bxi: true
          }
        },
        loaded: false
      }
    },
    methods: {
      loader: function(val){
          this.$emit('loader', val)
      },
      toggleStatusModal: function(){
        this.$modal.toggle('toggle-status');
      },
      async getClients(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/clients`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.clients = response.data.clients
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getClient(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/client?code=${this.code}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          await this.validateUser(response.data.isValid, response.data.exists)
          if (response.data.isValid){
            this.client = response.data.client
            if(this.client.isErp){
              this.erpSearch = this.client.name
              await this.getERPClients()
              this.client.erpName = this.options.erpClients.find(r => r.value === this.erpSearch)
            }
            this.client.phase ||= 0
            this.getProjects()
            this.getTotals()
            this.setForms()
          }
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getProjects(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/projects?client=${this.client._id}`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.projects = response.data.projects
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getBUnits(){
        this.loader(true);
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/bunits`,
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.options.bunits = response.data.bunits.filter(r => r.isActive).map(r => {
            return { key: r._id, text: r.name, value: r._id }
          })
          this.loader(false);
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getERPToken(){
        try {
          const response = await this.$openHttp.post(
            `${this.$erpEndpoint}/auth/auth/login`, {
              username: this.$erpUsername,
              password: this.$erpPassword
            }
          );
          this.erpToken = response.data.token
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getERPClients(){
        await this.getERPToken()
        try {
          const formData = new FormData()
          formData.append('target', 'client_name')
          formData.append('value', this.erpSearch)
          const response = await this.$openHttp.post(
            `${this.$erpEndpoint}/masterdata/customer/get_client_dtls`, formData,
            {
              headers: {
                'Authorization': `Bearer ${this.erpToken}`,
                'X-Requested-With': 'XMLHttpRequest'
              }
            }
          );
          this.options.erpClients = response.data.map(r => {
            return { key: r.client_id, text: r.client_name, value: r.client_name, record: r }
          })
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getGitProjects(){
        try {
          const response = await this.$http.get(
            `${this.$gitEndpoint}/search?scope=projects&search=${this.search}`,
            {
              headers: {
                'Authorization': `Bearer ${this.$gitToken}`
              }
            }
          );
          this.gitProjects = response.data
          this.options.gitProjects = this.gitProjects.map(r => {
            return { key: r.id, text: r.name_with_namespace, value: r.id, record: r }
          })
        } catch (error) {
          console.log(error)
          this.loader(false);
        }
      },
      async getTotals() {
        try {
          const response = await this.$http.get(
            `${this.$apiEndpoint}/v1/client/totals?client=${this.client._id}`,
              {
                headers: {
                  'Authorization': this.$userData.authToken,
                  'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          this.ticketStatistics = response.data.ticketStatistics
          this.customerStatistics = response.data.customerStatistics
        } catch (error) {
          console.log(error)
        }
      },
      validateUser: async function(isValid, exists){
        if(!isValid){
          this.$alertify({
            group: 'notification',
            title: `Access denied`,
            type: 'warning',
            text: exists ? `You don't have access to this client.` : `Client does not exist`
          })
          await this.$sleep(500);
          this.$router.push('/clients')
        }
      },
      updateClientProfile: function (){
        this.errors = []
        if(!this.options.phases.map(r => r.value).includes(this.client.phase))
          this.errors.push('Please select a deployment phase')
        if(this.client.name.trim() === '')
          this.errors.push('Client name cannot be empty')
        if(this.client.code.trim() === '')
          this.errors.push('Client code cannot be empty')
        else if(this.client.code.trim().length > 4)
          this.errors.push('Client code must only have four characters')
        else if(this.client.code.trim().length < 4)
          this.errors.push('Client code must have four characters')
        if(this.clients.filter(r => r._id != this.client._id).map(r => r.code).includes(this.client.code))
          this.errors.push('Client code already exists, try another one')
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Client Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitClientProfile()
        }
      },
      async submitClientProfile(){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/client/profile`,
            {
              client: {
                name: this.client.isErp ? this.client.erpName.value || this.client.erpName : this.client.name,
                isErp: this.client.isErp,
                code: this.client.code,
                logo: this.client.logo,
                remarks: this.client.remarks,
                segment: this.client.segment,
                phase: this.client.phase
              },
              _id: this.client._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.client._id) {
            this.getClient()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'Your changes have been saved.'
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
      },
      async updateClientStatus(){
        var status = !this.client.isActive
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/client/profile`,
            {
              client: {
                isActive: status
              },
              _id: this.client._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.client._id) {
            await this.getClient()
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: 'Client successfully ' + (response.data.client.isActive ? 'activated' : 'deactivated')
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
          this.$alertify({
            group: 'notification',
            title: 'Update Failed',
            type: 'warn',
            text: 'Something went wrong, please try again.'
          })
        }
        this.setForms()
        this.$modal.hide('toggle-status');
      },
      async updateClientProject(project){
        this.loader(true)
        try {
          const response = await this.$http.put(
            `${this.$apiEndpoint}/v1/client/project`,
            {
              project: {
                disabled: project.disabled
              },
              _id: project._id
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.project._id) {
            this.$alertify({
              group: 'notification',
              title: 'Update successful',
              type: 'success',
              text: `${project.gitLab.title} has been ${project.disabled ? 'disabled' : 'enabled'}`
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
        }
        this.getProjects()
      },
      createClientProject: function (){
        this.errors = []
        if(!this.project.key)
          this.errors.push('Project is required')
        if(!this.bunit.key)
          this.errors.push('Business Unit is required')
        if(this.projects.map(r => `${r.gitLab.id}-${r.bunit._id}`).includes(`${this.project.value}-${this.bunit.value}`))
          this.errors.push('Gitlab project already exists under selected business unit')
        if (this.errors.length > 0){
          this.errors.reverse().forEach(error => {
            this.$alertify({
              group: 'notification',
              title: 'Client Update',
              type: 'warn',
              text: error
            })
          });
          return false;
        }
        else {
          this.submitClientProject()
        }
      },
      async submitClientProject(){
        this.loader(true)
        try {
          const response = await this.$http.post(
            `${this.$apiEndpoint}/v1/client/project`,
            {
              project: {
                client: this.client._id,
                bunit: this.bunit.value,
                gitLab: {
                  id: this.project.record.id,
                  title: this.project.record.name_with_namespace,
                  url: this.project.record.web_url
                },
                disabled: false
              }
            },
            {
              headers: {
                'Authorization': this.$userData.authToken,
                'Access-Control-Allow-Origin' : '*',
              }
            }
          );
          if(response.data.project._id) {
            this.getProjects()
            this.$alertify({
              group: 'notification',
              title: 'Create successful',
              type: 'success',
              text: `${this.project.record.name_with_namespace} has been added`
            })
          }
        } catch (error) {
          this.loader(false)
          console.log(error)
        }
      },
      validateLogo: function(logo){
        return logo && logo.trim() !== '' ? logo : this.$defaultIrippleLogo
      },
      toggleIntegrations: function(){
        this.$modal.toggle('integrations');
      },
      setForms: function(){
        switch(this.user.role){
          case 'Admin':
            this.formControl = {
              profile: true,
              status: true,
              project: {
                new: this.client.isActive,
                update: this.client.isActive
              },
              integrations: {
                bxi: true
              }
            }
            break;
          case 'Manager':
            this.formControl = {
              profile: true,
              status: true,
              project: {
                new: false,
                update: false
              },
              integrations: {
                bxi: false
              }
            }
            break
          default:
            this.formControl = {
              profile: false,
              status: false,
              project: {
                new: false,
                update: false
              },
              integrations: {
                bxi: false
              }
            }
            break
        }
      }
    },
    mounted: function() {
      document.title = `${this.code} | iRipple Helpdesk`;
      this.getClients()
      this.getGitProjects()
      this.getClient()
      this.getBUnits()
      this.getERPToken()
      this.setForms()
      this.$watch('loaded', () => {
        this.$refs.searchSelect.firstElementChild.firstElementChild.nextElementSibling.addEventListener("keyup", (val) => {
          this.search = val.target.value
          this.getGitProjects()
        })
        this.$refs.searchSelect.firstElementChild.firstElementChild.nextElementSibling.addEventListener("blur", (val) => {
          this.search = val.target.value
          if(!this.project)
            this.getGitProjects()
        })
      });
      this.$watch('client.isErp', () => {
        if(this.$refs.erpSelect){
          this.$refs.erpSelect.firstElementChild.firstElementChild.nextElementSibling.addEventListener("keyup", (val) => {
            this.erpSearch = val.target.value
            this.getERPClients()
          })
          this.$refs.erpSelect.firstElementChild.firstElementChild.nextElementSibling.addEventListener("blur", (val) => {
            this.erpSearch = val.target.value
            if(!this.client.erpName)
              this.getERPClients()
          })
        }
      });
    },
    filters: {
      zeroPad: function(num){
        return String(num).padStart(4, '0')
      },
      rounded: function(num){
        return Math.round((num + Number.EPSILON) * 100) / 100
      }
    }
  }
</script>
